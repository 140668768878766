import './eiq-connect-fast-access.scss';
import '../../vehicles/vehicle-add-edit/vehicle-add-edit.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Divider, Form, Input, Radio, Space } from 'antd';
import InputPhone from 'common/components/form-elements/input-phone/input-phone';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import { useLocation, useNavigate } from 'react-router-dom';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { observer } from 'mobx-react-lite';
import { isServiceGuest } from 'common/helpers/guest-type.helpers';
import { DATE_SHORT_MMM_DD_YYYY, DATE_TIME_YYYY_MM_DD_HH_mm_ss_SSSZ } from 'common/constans/date.constants';
import { ReactComponent as QRCodeIcon } from 'assets/icons/eiq-connect/qr-code-btn.svg';
import { IGuestFastAccessPass } from './eiq-connect-fast-access.types';
import fastAccessService from 'common/services/fast-access-service/fast-access-service';
import { ICreateFastAccessPass, ISendGuestFastAccessPass } from 'common/services/fast-access-service/fast-access-service.types';
import { useTableContext } from 'common/components/table/table-context';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { dataURLtoBlob } from 'common/helpers/file.helper';
import QRCode from 'qrcode';
import dayjs from 'dayjs';
import { notification } from 'common/utils/notification';
import { sendFastAccessValidation } from './eiq-connect-fast-access.validation';
import EiqConnectFastAccessHeader from './components/eiq-connect-fast-access-header';
import { formatEiqPassportCode } from 'common/helpers/eiq-passport.helper';
import { GuestValidity } from 'common/enums/guest-validity.enum';

const { TextArea } = Input;
const initData = { type: 'email' };

const EiqConnectFastAccess = observer(() => {
  const { isMobileOrTable } = useMedia();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [isEmail, setIsEmail] = useState(true);
  const tableContext = useTableContext();
  const isFormSubmittedSuccessful = useRef(false);

  const [expiresValidationRules, setExpiresValidationRules] = useState<any>(sendFastAccessValidation.expires);

  useEffect(() => {
    if (!eiqConnectStore.residentDetails) {
      return;
    }

    const guestId = location.pathname.match(/\d+/)![0];
    const guest = eiqConnectStore.residentDetails?.addresses.flatMap((item) => item.guests).find((item) => item.id === Number(guestId));

    if (!guest) {
      return;
    }

    if (isServiceGuest(guest.type)) {
      form.setFieldValue('guestName', `${guest.companyName}`);
    } else {
      form.setFieldValue('guestName', `${guest.firstName} ${guest.lastName}`);
    }

    if (guest.validity !== GuestValidity.Permanent) {
      setExpiresValidationRules((rules: any) => [{ required: true, message: 'End date is required!' }, ...rules]);
    } else {
      form.setFieldValue('validFrom', dayjs().startOf('day'));
    }
  }, [eiqConnectStore.residentDetails]);

  useEffect(() => {
    return () => {
      if (!isFormSubmittedSuccessful.current) {
        notification.destroy();
      }
    };
  }, []);

  const onFinish = async (values: IGuestFastAccessPass) => {
    const selectedGuest = tableContext.getSelectedRow();

    if (!selectedGuest) {
      return;
    }

    const guestId = location.pathname.match(/\d+/)![0];
    const model: ICreateFastAccessPass = {
      addressId: selectedGuest.addressId,
      residentId: userAuthStore.webGuestResidentId!,
      guestId: Number(guestId),
      validFrom: values.validFrom.startOf('date').format(DATE_TIME_YYYY_MM_DD_HH_mm_ss_SSSZ),
      expires: values.expires.startOf('date').format(DATE_TIME_YYYY_MM_DD_HH_mm_ss_SSSZ),
      note: values.note,
      vehicleId: null,
    };

    const result = await fastAccessService.createFastAccessPass(model);
    const code = formatEiqPassportCode(result.id);

    QRCode.toDataURL(code, { errorCorrectionLevel: 'H', width: 200, margin: 2 }).then((url) => {
      const blob = dataURLtoBlob(url);
      const file = new File([blob], `${values.guestName}-${dayjs().unix()}.png`, { type: 'image/png' });

      const data: ISendGuestFastAccessPass = {
        file: file,
        contact: values.contact,
      };
      fastAccessService.sendGuestFastAccessPass(result.id, data).then(() => {
        isFormSubmittedSuccessful.current = true;
        notification.success({ message: 'EIQ Passport was successfully sent' });
        navigate(-1);
      });
    });
  };

  const onTypeChangedHandle = (e: any) => {
    if (e.target.value === 'email') {
      setIsEmail(true);
    } else {
      setIsEmail(false);
    }
  };

  const ActionButtons = () => {
    return (
      <>
        <Button type="link" onClick={() => navigate(-1)} className="button">
          Cancel
        </Button>
        <Button type="primary" icon={<QRCodeIcon />} className="button" htmlType="submit">
          Send EIQ Passport
        </Button>
      </>
    );
  };

  return (
    <FormWrapper
      form={form}
      onFinish={onFinish}
      initialValues={initData}
      layout="vertical"
      className="eiq-vehicle-add-edit eiq-add-edit-page">
      <EiqConnectFastAccessHeader title="EIQ Passport" isMobileOrTablet={isMobileOrTable} actions={<ActionButtons />} />
      <div className="eiq-content">
        <Space className="full-width">
          <div>
            <Form.Item name="guestName" label="Guest name" rules={sendFastAccessValidation.guestName}>
              <Input disabled />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
        </Space>
        <Space className="full-width">
          <div>
            <Form.Item name="validFrom" label="Valid from" rules={sendFastAccessValidation.validFrom}>
              <DatePicker className="full-width" placeholder="Valid from date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
          <div>
            <Form.Item name="expires" label="Expires" rules={expiresValidationRules} dependencies={['validFrom']}>
              <DatePicker className="full-width" placeholder="Expires date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
        </Space>
        <Space className="full-width">
          <div>
            <Form.Item name="type" className="eiq-input" label="Options to send">
              <Radio.Group className="vertical-radio-group" onChange={onTypeChangedHandle}>
                <Radio value="email">Email</Radio>
                <Radio value="phone">Phone number</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Space>
        <Space className="full-width">
          <div className="qr-code-contact">
            {isEmail ? (
              <Form.Item name="contact" label="Email" rules={sendFastAccessValidation.email}>
                <Input placeholder="Enter your email to send the code" />
              </Form.Item>
            ) : (
              <Form.Item name="contact" label="Phone number" rules={sendFastAccessValidation.phone}>
                <InputPhone placeholder="Enter your phone to send the code" />
              </Form.Item>
            )}
            <Divider className="account-diver" />
          </div>
          <div></div>
        </Space>
        <Space className="full-width">
          <div>
            <Form.Item name="note" label="Notes for a security officer">
              <TextArea placeholder="Notes for a security officer" className="eiq-text-area" />
            </Form.Item>
          </div>
          <div></div>
        </Space>
      </div>
      {isMobileOrTable && (
        <div className="eiq-qr-code-footer-actions">
          <ActionButtons />
        </div>
      )}
    </FormWrapper>
  );
});

export default EiqConnectFastAccess;
